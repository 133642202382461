import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'
import Gallery from "react-photo-gallery"
import { Button, Collapse, Table } from 'react-bootstrap'
import VolunteerPhoto from "../subviews/VolunteerPhoto";
import VolunteerProfile from "../subviews/VolunteerProfile";
import DecaturPhoto from "../assets/img/home/decatur.jpg"

import MainPhoto from '../assets/img/home/dye_photo9.jpg'
import { photos } from "../assets/gallery";
import '../assets/css/home.css'

const Home = () => {

  const navigate = useNavigate()
  const [open, setOpen] = useState(false)

  return (
    <div className="home">
      <div className="home-photo-wrapper">
        <img alt="Main" src={MainPhoto} />
        <div className="motto">
          To teach one is to reach one.
        </div>
      </div>
      <div className="information">
        <div className="information-wrapper">
          <div className="mission-statement">
            <h2>
              Who We Are
            </h2>
            <p>
              The Decatur Youth Enrichment’s mission is to enrich children’s knowledge through academic tutoring, art, music, drama and travel
              in order for them to give back to the community. To help parents develop stronger, positive relationships with their children
              and to have a positive social environment that will enhance productive citizens in society.
            </p>
            <p>
              Our goal is to help every child reach their academic level of learning and develop socially with their peers and teachers
              by becoming productive lifetime learners and workers within their community.
            </p>
          </div>
          <div className="details">
            <h3>
              What We Provide:
            </h3>
            <ul>
              <li className="link"><a onClick={() => navigate("/annual-trip")}>An Annual Trip</a></li>
              <li className="link"><a onClick={() => navigate("/car")}>C.A.R. Program</a></li>
              <li>After School Snacks</li>
              <li>An Environment for Group and Individual Leanring</li>
              <li>1v1 and Group Tutoring</li>
              <li>Mentorship Programs</li>
              <li>Frequent Visits and Speeches from Leaders in our Community</li>
              <li>A Safe Environment for Fostering Friendships and Academic Excellence</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="photo-gallery">
        <Gallery photos={photos} />
      </div>
      <div className="faq-enroll-wrapper">
        <div className="faq">
          <h2> In Our Program... </h2>
          <p>
            All Students improved in their study habits, which enable them to complete their grade course level
            to be promoted to the next grade level. We also had three students to be inducted into the National
            Honor Society. Evaluation of students is done through the student's
            report cards, progress reports, and conferences with their teachers.
          </p>
          <Button onClick={() => setOpen(!open)}>
            F.A.Q.
          </Button>
          <Collapse in={open}>
            <div id="collapse-text">
              <ol>
                <li>
                  <b>What is the total number of children that can be enrolled each year?</b>
                  <p> Our current capcity is around 30 to 35 children.</p>
                </li>
                <li>
                  <b>How much does it cost to attent Decature Youth Enrichment?</b>
                  <p> There is no cost to attend.</p>
                </li>
                <li>
                  <b>Are volunteers or staff paid?</b>
                  <p> No.</p>
                </li>
                <li>
                  <b>Are snacks provided?</b>
                  <p> Yes we serve after school snacks daily.</p>
                </li>
                <li>
                  <b>Is enrollement Lottery based? How do I apply?</b>
                  <p> No, it's first come first served. You can apply in person or by calling our number (256)-686-0079 during business hours.
                    You can download the enrollment form here:
                    <a className="download-button" href="dye_application.pdf" download> Download Application</a>
                  </p>
                </li>
                <li>
                  <b>Is there a waitlist?</b>
                  <p> Yes. </p>
                </li>
                <li>
                  <b>What are your Covid Protocols?</b>
                  <p> All surfaces are sanitized daily, and we check temputures at the door. Masks are also required by all students and faculty.</p>
                </li>
                <li>
                  <b>Does my child need to be vaccinated in order to attend?</b>
                  <p> No.</p>
                </li>
                <li>
                  <b>Do volunteers need to be vaccinated?</b>
                  <p> Yes, do to the saftey of our students and our staff, we ask that all of our volunteers be vacinnated. </p>
                </li>
                <li>
                  <b>In the event of severe weather or holiday, how are we notified of closures?</b>
                  <p> We follow the Decatur City School closure guidelines.</p>
                </li>
                <li>
                  <b>Is there a summer program?</b>
                  <p> Yes. The summer runs from June to August 1st.</p>
                </li>
                <li>
                  <b>What if my child has a food allergy?</b>
                  <p> We ask that you please inform us of any all when applying, and accomidate food/allergy restrictions.</p>
                </li>
                <li>
                  <b>Does DYE offer extended aftercare?</b>
                  <p> No. However in the rare case child in crisis or in further need of instruction we will accomidate</p>
                </li>
              </ol>
            </div>
          </Collapse>
        </div>
        <div className="enroll">
          <div className="img-content-wrapper">
            <img alt="decatur" src={DecaturPhoto} />
            <div className="enrollment-content">
              <div className="meta">
                <h2>Enroll Now</h2>
                <p> "Enriching the lives of youth for services in our world" </p>
                <a className="download-button" href='dye_application.pdf' download>Download Application</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="volunteers">
        <div className="volunteer-wrapper">
          <h2>Our Volunteers</h2>
          <div className="main-volunteers">
            <VolunteerProfile
              name="Maxine Ellison"
              photoId="volunteer-maxine"
              text=" Maxine Ellison is a retired teacher with Decatur City School System. She is a graduate of Cobb High School in Anniston, Al.
              She received a BS Degree in Elementary Education from Athens State University, and a MS Degree in Early Childhood from Alabama A&#38;M."
            />
            <VolunteerProfile
              name="Kinneth Priest"
              photoId="volunteer-ken"
              text=" Kin graduated from Austin High School, and has worked for the city school for over 20 years. In his spare time he enjoys writing poetry
               and working with Decatur's youth. He is one of our most consistent mentors and is a favorite among youth. "
            />
            <VolunteerProfile
              name="Stella Marshell"
              photoId="volunteer-stella"
              text=" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Arcu cursus vitae congue mauris rhoncus aenean vel elit scelerisque. Iaculis urna id volutpat lacus.
              Iaculis eu non diam phasellus vestibulum lorem sed risus ultricies."
            />
            <VolunteerProfile
              name="Jewel Freeman"
              photoId="volunteer-jewel"
              text=" Jewel retired from teaching for Decatur City School. In her spare time she enjoys listening to and playing music. She also teaches 
              handbells and basic music literacy for Decatur Youth Enrichment. She is known for her humanitarian efforts. "
            />
            <VolunteerProfile
              name="Edith Garner"
              photoId="volunteer-edith"
              text=" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Arcu cursus vitae congue mauris rhoncus aenean vel elit scelerisque. Iaculis urna id volutpat lacus.
              Iaculis eu non diam phasellus vestibulum lorem sed risus ultricies."
            />
          </div>
        </div>
      </div>
      <div className="other-volunteers">
        <h2>More Volunteers</h2>
        <Table bordered={false} className="vol-table">
          <tbody className="vol-table-body">
            <tr>
              <td> <VolunteerPhoto name="Reggie Priest" photoId="volunteer-reggie" />  </td>
              <td> <VolunteerPhoto name="Angela Kennedy" /></td>
              <td> <VolunteerPhoto name="Linda Ray" photoId="volunteer-linda" />  </td>
              <td> <VolunteerPhoto name="Sadie Bently" photoId="volunteer-sadie" /> </td>
            </tr>
            <tr>
              <td> <VolunteerPhoto name="April Priest" photoId="volunteer-april" /> </td>
              <td> <VolunteerPhoto name="Ralph Marshell" photoId="volunteer-ralph" /></td>
              <td> <VolunteerPhoto name="Jonathan Priest" photoId="volunteer-john" /> </td>
              <td> <VolunteerPhoto name="Dorthy Turner" /> </td>
            </tr>
            <tr>
              <td><VolunteerPhoto name="Billy Kennedy" /></td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div >
  );
}

export default Home;
