import React from "react"
import NoImage from '../assets/img/volunteers/no-image.jpg'

const VolunteerPhoto = ({ photoId, name = "" }) => {

  const loadPhoto = () => {
    try {
      return (
        <img alt="Other Volunteers" src={require(`../assets/img/volunteers/${photoId}.jpg`)} />
      )
    } catch (error) {
      return (
        <img alt="Other Volunteers" src={NoImage} />
      )
    }
  }

  return (
    <div className="other-volunteer">
      {loadPhoto()}
      <p className="vol-name">
        {name}
      </p>
    </div>
  )
}

export default VolunteerPhoto;