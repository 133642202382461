import React from "react";
import '../assets/css/car.css'

function CAR() {
  return (
    <div className="car-trip">
      <div className="car-temp">
        Coming Soon...
      </div>
    </div>
  );
}

export default CAR;
