import React from "react";
import { Container, Row, Col } from 'react-bootstrap'
import { PayPalButtons } from "@paypal/react-paypal-js";
import TextOverImage from "../subviews/TextOverImg";
import { FaFacebookSquare, FaYoutube, FaInstagram } from 'react-icons/fa'
import '../assets/css/support.css'

function Support() {
  return (
    <div className="support">
      <div className="support-banner">
        <Container fluid >
          <Row>
            <Col>
              <TextOverImage photoId="support1" text="Inspire" />
            </Col>
            <Col>
              <TextOverImage photoId="support2" text="Teach" />
            </Col>
            <Col>
              <TextOverImage photoId="support3" text="Community" />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="support-why">
        <h2>How to Donate</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Arcu cursus vitae congue mauris rhoncus aenean vel elit scelerisque. Iaculis urna id volutpat lacus.
          Iaculis eu non diam phasellus vestibulum lorem sed risus ultricies.
        </p>
      </div>
      <div className="support-info">
        <Row>
          <Col md={4} className="support-cash">
            <b>Send cash or checks to:</b>
            <p>TBD</p>
            <p>TBD</p>
            <p>TBD</p>
            <p>TBD</p>
          </Col>
          <Col md={2}  className="support-span">
            <b>- OR -</b>
          </Col>
          <Col md={4}>
            <div className="support-form">
              <PayPalButtons
                fundingSource={undefined}
                style={{ "layout": "vertical", "label": "donate" }}
                disabled={true}
                createOrder={(data, actions) => {
                  return actions.order
                    .create({
                      purchase_units: [
                        {
                          amount: {
                            value: "1",
                            breakdown: {
                              item_total: {
                                currency_code: "USD",
                                value: "1",
                              },
                            },
                          },
                          items: [
                            {
                              name: "donation-example",
                              quantity: "1",
                              unit_amount: {
                                currency_code: "USD",
                                value: "1",
                              },
                              category: "DONATION",
                            },
                          ],
                        },
                      ],
                    })
                    .then((orderId) => {
                      // Your code here after create the donation
                      return orderId
                    })
                }}
              />
            </div>
          </Col>

        </Row>
      </div>
      <div className="contact-footer">
        <div className="social-media">
          <h3>Follow Us On Social Media</h3>
          <div className="socials" >
            <span className="fb">
              <a href="https://www.facebook.com/Decatur-Youth-Enrichment-191909130667/">
                <FaFacebookSquare />
              </a>
            </span>
            <span className="yt"> <FaYoutube /> </span>
            <span className="inst"> <FaInstagram /> </span>
          </div>
          <div className="contact-info">
            <p>
              <b>Tel:</b> (256)-686-0079
            </p>
            <p>
              <b>Email:</b> beckymack2015@yahoo.com
            </p>
            <p>
              <b>Address:</b> 606 Vine St NW, Dectaur AL 35601
            </p>
            <p>
              <b>Hours of Operation:</b> Monday-Thrusday [2:00pm - 5:00pm]
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Support;
