import React from "react"
import '../assets/css/textOverImage.css'

const TextOverImage = ({ photoId, text = "" }) => {

  const loadPhoto = () => {
    try {
      return (
        <img alt="Text Over" src={require(`../assets/img/support/${photoId}.jpg`)} />
      )
    } catch (error) {
     console.error("Error Loading Image: " + photoId)
    }
  }

  return (
    <div className="text-over-img">
      {loadPhoto()}
      <div className="text">
        <p>{text}</p>
      </div>
    </div>
  )
}

export default TextOverImage;