import React from "react"
import { useNavigate } from 'react-router-dom'
import '../assets/css/header.css'

const Header = () => {
  const navigate = useNavigate()

  return (
    <div className="header">
      <div className="header-title" onClick={() => navigate("/")}>
        <div className="title">
          Decatur Youth Enrichment
        </div>
      </div>
      <div className="header-nav">
        <div className="header-home" onClick={() => navigate("/")}>
          Home
        </div>
        <div className="header-contact" onClick={() => navigate("/contact")}>
          Contact
        </div>
        <div className="header-events" onClick={() => navigate("/support-us")}>
          Support Us
        </div>
        <div className="header-events" onClick={() => navigate("/get-involved")}>
          Get Involved
        </div>
        <div className="header-events" onClick={() => navigate("/annual-trip")}>
          Annual Trip
        </div>
      </div>
    </div>
  );
}

export default Header;
