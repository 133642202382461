import React from "react"
import { FaCheckCircle } from 'react-icons/fa';

import '../assets/css/emailsent.css'

const EmailSent = () => {

  return (
    <div className="email-sent">
      <span> Success! <FaCheckCircle /> </span>
      <div className="text">
        <p className="thanks">
          Thank you for contacting Decatur Youth Enrichment!
        </p>
        <p>
          A memeber of or staff will be in touch with you soon.
          In the meantime, please checkout our FAQ section on our homepage for more information.
        </p>
      </div>
    </div>
  )
}

export default EmailSent;