/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "email",
            "endpoint": "https://ek4bxy5940.execute-api.us-west-2.amazonaws.com/master",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
