import React from "react";
import '../assets/css/annualTrip.css'

function AnnualTrip() {
  return (
    <div className="annual-trip">
      <div className="annual-trip-temp">
        Coming Soon...
      </div>
    </div>
  );
}

export default AnnualTrip;
