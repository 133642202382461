import React from "react";
import '../assets/css/getInvolved.css'

function GetInvolved() {
  return (
    <div className="get-involved">
      <div className="get-involved-temp">
        Coming Soon...
      </div>
    </div>
  );
}

export default GetInvolved;
