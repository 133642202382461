import React from "react";
import '../assets/css/pay.css'

function PayOnline() {
  return (
    <div className="pay">
      <div className="pay-temp">
        Coming Soon...
      </div>
    </div>
  );
}

export default PayOnline;
