import React, { useState } from "react"
import { Button, Form, Row, Col } from 'react-bootstrap'
import { API } from 'aws-amplify'
import EmailSent from "../subviews/EmailSent"

import ContactImg from '../assets/img/president.png'
import { FaFacebookSquare, FaYoutube, FaInstagram } from 'react-icons/fa'

import '../assets/css/contact.css'

function Contact() {

  const [validated, setValidated] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const apiName = 'email';
  const path = '/email';
  const myInit = {
    body: {
      name: `${firstname} ${lastname}`,
      email: email,
      message: message
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      API.post(apiName, path, myInit)
        .then(response => {
          // console.log(response)
        })
        .catch(error => {
          console.log(error.response);
        });
      event.preventDefault();
      setEmailSent(true)
    }
    setValidated(true);
  }

  return (
    <>
      <div className="contact-header">
        <div className="contact-img-wrapper">
          <img className="contact-img" alt="Maxine Ellison" src={ContactImg} />
        </div>
        <div className="description">
          <h1> Maxine Ellison</h1>
          <p>
            Maxine Ellison is a retired teacher with Decatur City School System. She is a graduate of Cobb High School in Anniston, Al.
            She received a BS Degree in Elementary Education from Athens State University, and a MS Degree in Early Childhood from Alabama A&#38;M.
          </p>
          <p>
            In 1986 she became the founder and president of the Decatur Youth Enrichment Program.
            The program is designed to service the youth of the community to excel, succeed high above adversity
            in academics and life crisis.
          </p>
          <p>
            Maxine became the founder of the LIFE (Learning Is For Everyone) Program in Decatur Housing Authority.
            This program is designed to bring self awareness, motivation, and create a work ethic in those that attend.
          </p>
          <p>
            She is a member of Alpha Seventh Day Adventist Church and also teaches a
            community Bible Study class at Turner-Surles Community Center. For 11 years,
            she has taught the Bible class” John 3:16” at the USA Nursing Home and Rehabilitation Center.
          </p>
          <p>
            Maxine has received the following Awards:
            2018 Spirit of America Humanitarian award, NAACP Community Service Award, 1998 Decatur Chamber of Commerce Humanitarian Award, Community Leader of the year award, Outstanding Community Service Award, Minority Business Leader Community Service Award, and the Elk Lodge Outstanding Service Award.
          </p>
        </div>
      </div>
      <div className="contact-form">

        {emailSent ? <EmailSent /> :
          <>
            <h2> Contact Us </h2>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" >
                  <Form.Label>First name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="First name"
                    onChange={(event) => setFirstname(event.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide your first name.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>Last name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Last name"
                    onChange={(event) => setLastname(event.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide your last name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" >
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    placeholder="Email Address"
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide an email address.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" >
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    className="form-text"
                    required
                    as="textarea"
                    placeholder="Provide a message here"
                    onChange={(event) => setMessage(event.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a message.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Button type="submit">Submit</Button>
            </Form>
          </>
        }
      </div>
      <div className="contact-footer">
        <div className="social-media">
          <h3>Follow Us On Social Media</h3>
          <div className="socials" >
            <span className="fb">
              <a href="https://www.facebook.com/Decatur-Youth-Enrichment-191909130667/">
                <FaFacebookSquare />
              </a>
            </span>
            <span className="yt"> <FaYoutube /> </span>
            <span className="inst"> <FaInstagram /> </span>
          </div>
          <div className="contact-info">
            <p>
              <b>Tel:</b> (256)-686-0079
            </p>
            <p>
              <b>Email:</b> beckymack2015@yahoo.com
            </p>
            <p>
              <b>Address:</b> 606 Vine St NW, Dectaur AL 35601
            </p>
            <p>
              <b>Hours of Operation:</b> Monday-Thrusday [2:00pm - 5:00pm]
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact
