import React from "react"
import NoImage from '../assets/img/volunteers/no-image.jpg'

const VolunteerProfile = ({ photoId = "no-image", name, text = "" }) => {

  const loadPhoto = () => {
    try {
      return (
        <img alt="Main Volunteer" src={require(`../assets/img/volunteers/${photoId}.jpg`)} />
      )
    } catch (error) {
      return (
        <img alt="Main Volunteer" src={NoImage} />
      )
    }
  }

  return (
    <div className="volunteer-profile">
      <div className="vol-photo-wrapper">
        {loadPhoto()}
      </div>
      <div>
        <p className="vol-name">
          {name}
        </p>
        <p>
          {text}
        </p>
      </div>
    </div>
  )
}

export default VolunteerProfile