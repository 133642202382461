import React from 'react'
import { render } from "react-dom"
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom"
import Home from './pages/Home'
import Contact from './pages/Contact'
import PayOnline from './pages/PayOnline'
import AnnualTrip from './pages/AnnualTrip'
import GetInvolved from './pages/GetInvolved'
import Support from './pages/Support'
import CAR from './pages/CAR'
import Header from './subviews/Header'
import { PayPalScriptProvider } from "@paypal/react-paypal-js";


import 'bootstrap/dist/css/bootstrap.min.css'

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

const rootElement = document.getElementById("root")
render(
  <PayPalScriptProvider options={{ "client-id": "test" }}>
    <div className="App">
      {/* TODO Need page not found */}
      <BrowserRouter>
        {/* Header Is Global */}
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/car" element={<CAR />} />
          <Route path="/annual-trip" element={<AnnualTrip />} />
          <Route path="/support-us" element={<Support />} />
          <Route path="/get-involved" element={<GetInvolved />} />
          <Route path="/pay" element={<PayOnline />} />
        </Routes>
      </BrowserRouter>
    </div>
  </PayPalScriptProvider>,
  rootElement
);