export const photos = [
    {
        src: require("./img/gallery/group.jpg"),
        width: 4,
        height: 3
    },
    {
        src: require('./img/gallery/group2.jpg'),
        width: 3,
        height: 3
    },
    {
        src: require('./img/gallery/group3.jpg'),
        width: 4,
        height: 3
    },
    {
        src: require('./img/gallery/group4.jpg'),
        width: 2,
        height: 3
    },
    {
        src: require('./img/gallery/group5.jpg'),
        width: 4,
        height: 3
    },

    {
        src: require('./img/gallery/group7.jpg'),
        width: 2,
        height: 3,
        class: 'desktop'
    },
    {
        src: require('./img/gallery/group12.jpg'),
        width: 3,
        height: 3,
        class: 'desktop'
    },
    {
        src: require('./img/gallery/group6.jpg'),
        width: 4,
        height: 3,
        class: 'desktop'
    },
    {
        src: require('./img/gallery/group8.jpg'),
        width: 3,
        height: 3,
        class: 'desktop'
    },
    {
        src: require('./img/gallery/group9.jpg'),
        width: 4,
        height: 3,
        class: 'desktop'
    },
    {
        src: require('./img/gallery/group10.jpg'),
        width: 3,
        height: 3,
        class: 'desktop'
    },
    {
        src: require('./img/gallery/group11.jpg'),
        width: 3,
        height: 3,
        class: 'desktop'
    }

];